import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import AGB_DE from "../../static/PDF/AGB_DE_V1.00.pdf"
import style from "./style.module.scss"

import Layout from "components/Layout/de"

export default function(props) {
  const header = {
    text: "MoonVision Allgemeine Geschäftsbedingungen",
    description:
      "MoonVision Allgemeine Geschäftsbedingungen. Diese Bestimmungen regeln Ihre Nutzung unseres Dienstes.",
  }
  return (
    <Layout header={header} location={props.location}>
      <main class="white-background">
        <Container>
          <Row className="py-4">
            <Col md="8" className="py-3">
              <h1>Allgemeine Geschäftsbedingungen</h1>
            </Col>
            <Col md="8" className="py-3">
              <p>
                Diese Bestimmungen regeln Ihre Nutzung unseres Dienstes.
                <a className={style.linkInsideParagraph} href={AGB_DE}>
                  Aktuelle Version
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </main>
    </Layout>
  )
}
